const becomePatientStrings =
{
	title: "Become a Patient",
    link: "/become-a-patient/",
    description:
      "Are you looking for a medical marijuana card in your state? Find out the requirements for becoming a cannabis patient in select states below.",
  	bpa_title: "Become a Patient",
	bpa_desc:
	    "Are you looking for a medical marijuana card in your state? Find out the requirements for becoming a cannabis patient in select states below.",
	bpa_note_title: "PLEASE NOTE: ",
	bpa_note:
	    "We do our best to keep things up to date. But the information here is subject to change based on local and federal laws. Please check with your state for the latest information. Proof of state residency and other forms of identification are required.",
	bpa_select_placeholder: "Select a state",
  	california: "California",
	maryland: "Maryland",
	massachusetts: "Massachusetts",
	michigan: "Michigan",
	// missouri: "Missouri",
	pennsylvania: "Pennsylvania",

	register_ca: "Register for the program by completing this ",
	form: " form.",
	recommendation_ca: "Get a written recommendation from your doctor for an ",
	register: "Register for the ",
	program: "program.",
	get_proof: "Get proof of an ",
	approved: "approved medical condition.",
	approved_mi: "approved medical condition",
	apply: "Apply ",
	in_person: "in-person",
	for_id: " for a medical marijuana ID card.",
	from_doctor: " from your doctor.",
	pay_for_id: "Pay for a medical marijuana ID card.",
	pay_for_id_mi: "Pay for a medical marihuana ID card.",
	get_medical: "Get medical marijuana from an ",
	approved_disp: "approved dispensary",
	thatsus: "that’s us!",
	visit_md: "Visit a licensed Maryland dispensary",
	register_email: "Register by mail or ",
	electronically: "electronically.",
	need_pin: "You will need your PIN number to complete ",
	registration: "registration.",
	register_md: "Register for the program. Info for adults and caregivers can be found ",
	here: "here.",
	more_info: "More info",
	primary_doctor: "from your primary doctor.",
	mi_cert: "Get a written certification from a registered medical marijuana doctor.",
	mi_license: "Get medical marijuana from a licensed provisioning center",
	contact_url: "/contact-us",
	mo_point_one: "Make sure you have a ",
	mo_point_one_link: "qualifying medical condition",
	mo_point_two: "for the program",
	mo_point_two_link: "Apply and register",
	mo_point_three: " for a medical marijuana card",
	mo_point_three_link: "Pay",
	mo_point_four: "Get medical cannabis from an approved dispensary ",
	mo_point_four_link: "that’s us",
}

module.exports = becomePatientStrings
